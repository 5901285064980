<template>
    <section class="vh-100 gradient-game">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4">
                                <h2 class="fw-bold mb-2 text-uppercase text-center">Waiting room</h2>
                                <p class="text-black-50 text-center">Let's wait on others to join this game.</p>

                                <div class="d-flex justify-content-center mb-5">
                                    <a class="link-primary" @click="copyInvitationLink">Copy invitation link</a>
                                </div>

                                <div class="alert alert-danger" v-if="showError">
                                    <ul class="list-unstyled mb-0">
                                        <li v-for="(itemErrors, key) in errors" :key="key">
                                            {{ itemErrors[0] }}
                                        </li>
                                    </ul>
                                </div>

                                <ul class="list-group mb-5">
                                    <li v-for="player in players" :key="player.uid" class="list-group-item">
                                        {{ player.username }}
                                    </li>
                                </ul>

                                <div class="d-flex justify-content-between" v-if="owner">
                                    <button class="btn btn-danger btn-lg px-5" @click="cancel">Cancel</button>
                                    <button class="btn btn-success btn-lg px-5" @click="start">Start</button>
                                </div>
                                <div class="d-flex justify-content-start" v-else>
                                    <button class="btn btn-danger btn-lg px-5" @click="leave">Leave</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from "axios";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'GameWaitingRoom',
    data() {
        return {
            game: null,
            owner: false,
            players: [],
            showError: false,
            errors: [],
        };
    },
    mounted: function () {
        axios.get(`/game/${this.$route.params.uid}/players`).then((response) => {
            if (response.data.started === true) {
                this.$router.push(`/game/${response.data.uid}`);
            }

            this.game = response.data.uid;
            this.owner = response.data.owner;
            this.players = response.data.players;

            this.$pusher.subscribe(this.game);

            this.subscribe();
        });
    },
    methods: {
        subscribe() {
            this.$pusher.get().bind('App\\Events\\GameJoined', (response) => {
                this.players.push(response.player);
            });

            this.$pusher.get().bind('App\\Events\\GameLeft', (response) => {
                this.players = this.players.filter(player => player.uid !== response.player.uid);
            });

            this.$pusher.get().bind('App\\Events\\GameCanceled', () => {
                this.$router.push(`/game`);
            });

            this.$pusher.get().bind('App\\Events\\GameStarted', () => {
                this.$router.push(`/game/${this.game}`);
            });
        },
        async start() {
            try {
                const response = await axios.post(`/game/${this.game}/start`);
                this.$router.push(`/game/${response.data.uid}`);
            } catch (error) {
                this.errors = error.status === 422 ? error.data.errors : [['Whoops, something went wrong...']]
                this.showError = true
            }
        },
        async cancel() {
            await axios.delete(`/game/${this.game}`);
            this.$router.push(`/game`);
        },
        async leave() {
            await axios.delete(`/game/${this.game}/leave`);
            this.$router.push(`/game`);
        },
        async copyInvitationLink() {
            const link = window.location.origin + '/game/join?invite=' + this.game;
            navigator.clipboard.writeText(link);

            messageStore.addItem({'message': 'Invitation link copied to clipboard.', 'color': 'success'});
        }
    },
}
</script>
