<template>
    <section class="vh-100 gradient-custom">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-dark text-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw-bold mb-2 text-uppercase mb-5 text-center">Register</h2>

                                <form @submit.prevent="submit">
                                    <div class="alert alert-danger" v-if="showError">
                                        <ul class="list-unstyled mb-0">
                                            <li v-for="(itemErrors, key) in errors" :key="key">
                                                {{ itemErrors[0] }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="firstname">First name</label>
                                        <input type="text" name="firstname" v-model="form.firstname" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="lastname">Last name</label>
                                        <input type="text" name="lastname" v-model="form.lastname" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="username">Username</label>
                                        <input type="text" name="username" v-model="form.username" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="email">Email address</label>
                                        <input type="email" name="email" v-model="form.email" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="password">Password</label>
                                        <input type="password" name="password" v-model="form.password" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="password_confirmation">Password confirmation</label>
                                        <input type="password" name="password_confirmation" v-model="form.password_confirmation" class="form-control form-control-lg">
                                    </div>

                                    <div class="text-center">
                                        <button class="btn btn-outline-light btn-lg px-5" type="submit">Register</button>
                                    </div>
                                </form>
                            </div>

                            <div class="text-center">
                                <p class="mb-0">Oh it seems like you have an account? <a @click="logIn()" class="text-white-50 fw-bold">Log in!</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";
export default {
    name: "AuthRegister",
    components: {},
    data() {
        return {
            form: {
                firstname: null,
                lastname: null,
                username: null,
                email: null,
                password: null,
                password_confirmation: null,
            },
            showError: false,
            errors: [],
        };
    },
    methods: {
        ...mapActions(["Register"]),
        async submit() {
            try {
                await this.Register(this.form);
                this.$router.push("/game");
                this.showError = false
            } catch (error) {
                if (error.status === 422) {
                    this.errors = error.data.errors;
                } else {
                    this.errors = ['Whoops, something went wrong...']
                }
                this.showError = true
            }
        },
        async logIn() {
            this.$router.push("/login");
        },
    },
};
</script>
