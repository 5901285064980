<template>
    <div :class="'deck ' + (myTurn ? 'active' : '')">
        <h4>Deck</h4>

        <div ref="deck" class="card-back" @contextmenu.prevent="showMenu($event)">
            <img :src="image" alt="unstabled deck" ondragstart="return false;" />
        </div>

        <div v-if="myTurn" id="contextMenuDeck" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="drawCard"><i class="fas fa-plus" aria-hidden="true"></i> Draw a card</a></li>
                <li class="divider"></li>
                <li><a @click="shuffleDeck"><i class="fas fa-random" aria-hidden="true"></i> Shuffle</a></li>
                <li><a class="red" @click="$emit('showModal')"><i class="fas fa-eye" aria-hidden="true"></i> Take a look</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'
import gameApi from "@/services/gameApi";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'CardDeck',
    props: {
        myTurn: Boolean,
        cards: Array,
    },
    data() {
        return {
            image: process.env.VUE_APP_IMAGES_BASE_URL + '/cards/back.png',
            loading: false,
            menu: false,
        };
    },
    computed: {
        disabled() {
            return this.myTurn === false || this.loading === true;
        },
    },
    watch: {
        myTurn: function (myTurnValue) {
            this.tooltip = myTurnValue ? new Tooltip(this.$refs.deck) : null;
        }
    },
    methods: {
        showMenu(event) {
            if (this.myTurn === false) { return false; }

            const menu = document.getElementById("contextMenuDeck")
            const element = document.getElementById('middle').getBoundingClientRect();
            menu.style.left = (event.clientX - element.left) + "px";
            menu.style.top = (event.clientY - element.top) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuDeck")
            menu.style.display = 'none';
        },
        async drawCard() {
            if (this.myTurn === false) { return false; }

            this.hideMenu();

            try {
                const response = await gameApi.drawCard();
                this.$emit('drawCard', {'card': response.data});
            } catch (error) {
                messageStore.error(error);
            }
        },
        async shuffleDeck() {
            if (this.myTurn === false) { return false; }

            this.hideMenu();

            try {
                await gameApi.shuffleDeck();
                messageStore.addItem({'message': 'You shuffled the deck!'});
            } catch (error) {
                messageStore.error(error);
            }
        },
    }
}
</script>