<template>
    <section class="vh-100 gradient-game">
        <div class="game">
            <div :class="'playmat players-' + (players.length + 1)">
                <div class="opponents">
                    <opponent-stable v-for="(opponent, index) in opponents" :key="opponent.uid"
                                     :player="opponent.player"
                                     :index="index + 1"
                                     :active="currentPlayer === opponent.player.uid"
                                     :my-turn="myTurn"
                                     :cards="opponent.cards"
                                     :order="(opponent.order + 1)"
                                     @remove-card-from-hand="removeCardFromHand"
                                     @remove-card-from-stable="removeCardFromStable"
                                     @remove-card-from-opponent="removeCardFromOpponent"
                                     @add-card-to-opponent="addCardToOpponent"
                                     @show-modal="showOpponentModal"
                                     @show-hand="showOpponentHand"
                    ></opponent-stable>
                </div>

                <div class="middle" id="middle">
                    <nursery-pile :cards="nursery"
                                  :my-turn="myTurn"
                                  @remove-card-from-stable="removeCardFromStable"
                                  @add-card-to-nursery="addCardToNursery"
                                  @show-modal="showNurseryModal"
                    ></nursery-pile>
                    <card-deck :my-turn="myTurn"
                               @draw-card="addCardToHand"
                               @show-modal="showDeckModal"
                    ></card-deck>
                    <table-zone :my-turn="myTurn"
                                :cards="table"
                                @remove-card-from-hand="removeCardFromHand"
                                @add-card-to-table="addCardToTable"
                                @show-modal="showTableModal"
                    ></table-zone>
                    <discard-pile :cards="discardPile"
                                  :my-turn="myTurn"
                                  @remove-card-from-hand="removeCardFromHand"
                                  @remove-card-from-stable="removeCardFromStable"
                                  @remove-card-from-opponent="removeCardFromOpponent"
                                  @remove-card-from-table="removeCardFromTable"
                                  @add-card-to-discard-pile="addCardToDiscardPile"
                                  @clear-discard-pile="clearDiscardPile"
                                  @show-modal="showDiscardPileModal"
                    ></discard-pile>
                </div>
                <div class="bottom">
                    <player-stable :cards="stable.cards"
                                   :cards-in-hand="hand.length"
                                   :order="(stable.order + 1)"
                                   :my-turn="myTurn"
                                   :end-turn-counter="endTurnCounter"
                                   @remove-card-from-hand="removeCardFromHand"
                                   @remove-card-from-opponent="removeCardFromOpponent"
                                   @add-card-to-stable="addCardToStable"
                                   @show-modal="showStableModal"
                    ></player-stable>
                    <player-hand :cards="hand"
                                 @remove-card-from-stable="removeCardFromStable"
                                 @remove-card-from-table="removeCardFromTable"
                                 @add-card-to-hand="addCardToHand"
                                 @show-modal="showHandModal"
                    ></player-hand>
                </div>
            </div>
        </div>

        <opponent-stable-modal v-for="opponent in opponents" :key="'modal' + opponent.uid"
                               :player="opponent.player"
                               :cards="opponent.cards"
                               :index="modals.opponents[opponent.player.uid] ? modals.opponents[opponent.player.uid].index || 0 : null"
                               :modal-open="modals.opponents[opponent.player.uid] ? modals.opponents[opponent.player.uid].open || false : false"
                               @close-modal="modals.opponents[opponent.player.uid].open = false"
        ></opponent-stable-modal>
        <opponent-hand-modal :cards="[]" :index="0"
                             :modal-open="modals.opponentHand.open || false"
                             :player="modals.opponentHand.player"
                             :my-turn="myTurn"
                             @add-card-to-hand="addCardToHand"
                             @close-modal="modals.opponentHand.open = false"
        ></opponent-hand-modal>

        <nursery-pile-modal :cards="nursery"
                            :index="modals.nursery.index || 0"
                            :modal-open="modals.nursery.open || false"
                            :my-turn="myTurn"
                            @remove-card-from-nursery="removeCardFromNursery"
                            @add-card-to-stable="addCardToStable"
                            @close-modal="modals.nursery.open = false"
        ></nursery-pile-modal>
        <card-deck-modal :cards="[]" :index="0"
                         :modal-open="modals.deck.open || false"
                         :my-turn="myTurn"
                         @add-card-to-hand="addCardToHand"
                         @add-card-to-stable="addCardToStable"
                         @close-modal="modals.deck.open = false"
        ></card-deck-modal>
        <table-zone-modal :cards="table"
                          :index="modals.table.index || 0"
                          :modal-open="modals.table.open || false"
                          :my-turn="myTurn"
                          @close-modal="modals.table.open = false"
        ></table-zone-modal>
        <discard-pile-modal :cards="discardPile"
                            :index="modals.discardPile.index || 0"
                            :modal-open="modals.discardPile.open || false"
                            :my-turn="myTurn"
                            @remove-card-from-discard-pile="removeCardFromDiscardPile"
                            @add-card-to-hand="addCardToHand"
                            @add-card-to-stable="addCardToStable"
                            @close-modal="modals.discardPile.open = false"
        ></discard-pile-modal>

        <player-stable-modal :cards="stable.cards"
                             :index="modals.stable.index || 0"
                             :modal-open="modals.stable.open || false"
                             :my-turn="myTurn"
                             @close-modal="modals.stable.open = false"
        ></player-stable-modal>
        <player-hand-modal :cards="hand"
                           :index="modals.hand.index || 0"
                           :modal-open="modals.hand.open || false"
                           :my-turn="myTurn"
                           @close-modal="modals.hand.open = false"
        ></player-hand-modal>
    </section>
</template>

<script>

import { mapGetters } from "vuex";
import { messageStore } from "@/services/messageStore";
import axios from "axios";
import CardDeck from "@/components/CardDeck";
import CardDeckModal from "@/components/CardDeckModal.vue";
import DiscardPile from "@/components/DiscardPile";
import DiscardPileModal from "@/components/DiscardPileModal.vue";
import NurseryPile from "@/components/NurseryPile";
import NurseryPileModal from "@/components/NurseryPileModal.vue";
import OpponentHandModal from "@/components/OpponentHandModal.vue";
import OpponentStable from "@/components/OpponentStable";
import OpponentStableModal from "@/components/OpponentStableModal.vue";
import PlayerHand from "@/components/PlayerHand";
import PlayerHandModal from "@/components/PlayerHandModal.vue";
import PlayerStable from "@/components/PlayerStable";
import PlayerStableModal from "@/components/PlayerStableModal.vue";
import TableZone from "@/components/TableZone";
import TableZoneModal from "@/components/TableZoneModal.vue";
import gameApi from "@/services/gameApi";

export default {
    name: 'UnstableUnicorns',
    components: {
        CardDeck,
        CardDeckModal,
        DiscardPile,
        DiscardPileModal,
        NurseryPile,
        NurseryPileModal,
        OpponentHandModal,
        OpponentStable,
        OpponentStableModal,
        PlayerHand,
        PlayerHandModal,
        PlayerStable,
        PlayerStableModal,
        TableZone,
        TableZoneModal,
    },
    data() {
        return {
            loading: true,
            gameUid: null,
            isOwner: false,
            currentPlayer: null,
            myTurn: false,
            endTurnTimer: null,
            endTurnCounter: 10,
            colors: [],
            opponents: [],
            players: [],
            hand: [],
            stable: {cards: []},
            deck: [],
            discardPile: [],
            nursery: [],
            table: [],
            modals: {
                opponents: {},
                hand: { open: false, index: 0 },
                stable: { open: false, index: 0 },
                deck: { open: false, index: 0 },
                discardPile: { open: false, index: 0 },
                nursery: { open: false, index: 0 },
                table: { open: false, index: 0 },
                opponentHand: { open: false, player: null }
            },
        }
    },
    mounted: function () {
        axios.get(`/game/${this.$route.params.uid}`).then((response) => {
            this.gameUid = response.data.uid;
            this.isOwner = response.data.owner;
            this.currentPlayer = response.data.current_player;
            this.myTurn = this.currentPlayer === this.getUser().uid;

            this.opponents = response.data.opponents;
            this.players = response.data.opponents.map((opponent) => opponent.player);
            this.colors = response.data.opponents.map((opponent) => opponent.player.order)

            this.hand = response.data.hand;
            this.stable = response.data.stable;

            this.discardPile = response.data.discard_pile;
            this.nursery = response.data.nursery;
            this.table = response.data.table;

            window.localStorage.setItem('unstabled.game', this.gameUid)
            this.$pusher.subscribe(this.gameUid);

            this.registerEvents();
            messageStore.addMessages(response.data.messages);

            this.loading = false;

            this.endTurnTimer = setInterval(() => {
                this.countDownForEndingTurn()
            }, 1000);
        }).catch((error) => {
            messageStore.error(error);
        });
    },
    methods: {
        ...mapGetters(["getUser"]),

        registerEvents() {
            this.registerGlobalEvent();
            this.registerEventsFromHand();
            this.registerEventsFromStable();
            this.registerEventsFromOpponent();
            this.registerEventsFromNursery();
            this.registerEventsFromDeck();
            this.registerEventsFromTable();
            this.registerEventsFromDiscardPile();
            this.registerEndTurnEvent();
        },

        registerGlobalEvent()
        {
            this.$pusher.get().bind_global(() => {
                this.endTurnCounter = 10;
            });
        },

        registerEventsFromHand() {
            this.$pusher.get().bind('App\\Events\\Game\\Hand\\CardAddedToStable', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                response.player.uid === this.getUser().uid
                    ? this.addCardToStable({'card': response.card})
                    : this.addCardToOpponent({'player': response.player, 'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Hand\\CardDiscarded', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToDiscardPile({'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Hand\\CardDrawn', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Hand\\InstantCardPlayed', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToTable({'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Hand\\MagicCardPlayed', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToTable({'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        registerEventsFromStable() {
            this.$pusher.get().bind('App\\Events\\Game\\Stable\\CardDestroyed', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                response.player.uid === this.getUser().uid
                    ? this.removeCardFromStable({'index': response.index})
                    : this.removeCardFromOpponent({'player': response.player, 'index': response.index});

                this.addCardToDiscardPile({'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Stable\\CardMovedStables', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                response.source.uid === this.getUser().uid
                    ? this.removeCardFromStable({'index': response.index})
                    : this.removeCardFromOpponent({'player': response.source, 'index': response.index});

                response.player.uid === this.getUser().uid
                    ? this.addCardToStable({'card': response.card})
                    : this.addCardToOpponent({'player': response.player, 'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Stable\\CardSacrificed', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.removeCardFromOpponent({'player': response.currentPlayer, 'index': response.index});
                this.addCardToDiscardPile({'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Stable\\CardStolen', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToOpponent({'player': response.currentPlayer, 'card': response.card});

                response.player.uid === this.getUser().uid
                    ? this.removeCardFromStable({'index': response.index})
                    : this.removeCardFromOpponent({'player': response.player, 'index': response.index});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Stable\\CardWentBackToHand', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.removeCardFromOpponent({'player': response.player, 'index': response.index});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Stable\\CardWentBackToNursery', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToNursery({'card': response.card});
                this.removeCardFromOpponent({'player': response.currentPlayer, 'index': response.index});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        registerEventsFromOpponent() {
            this.$pusher.get().bind('App\\Events\\Game\\Opponent\\HandViewed', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Opponent\\HandSwitched', (response) => {
                if (response.currentPlayer.uid !== this.getUser().uid) {
                    messageStore.addItem({'message': response.message, 'color': response.color});
                }

                if (response.currentPlayer.uid !== this.getUser().uid && response.player.uid !== this.getUser().uid) {
                    return;
                }

                gameApi.fetchHand().then((response) => {
                    this.hand = response.data.hand;
                });
            });

            this.$pusher.get().bind('App\\Events\\Game\\Opponent\\CardAddedToHand', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                messageStore.addItem({'message': response.message, 'color': response.color});

                if (response.player.uid === this.getUser().uid) {
                    gameApi.fetchHand().then((response) => {
                        this.hand = response.data.hand;
                    });
                }
            });

            this.$pusher.get().bind('App\\Events\\Game\\Opponent\\RandomCardAddedToHand', (response) => {
                if (response.currentPlayer.uid !== this.getUser().uid) {
                    messageStore.addItem({'message': response.message, 'color': response.color});
                }

                if (response.currentPlayer.uid !== this.getUser().uid && response.player.uid !== this.getUser().uid) {
                    return;
                }

                gameApi.fetchHand().then((response) => {
                    this.hand = response.data.hand;
                });
            });
        },

        registerEventsFromNursery() {
            this.$pusher.get().bind('App\\Events\\Game\\Nursery\\BabyAdopted', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToOpponent({'player': response.currentPlayer, 'card': response.card});
                this.removeCardFromNursery({'index': response.index});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        registerEventsFromDeck() {
            this.$pusher.get().bind('App\\Events\\Game\\Deck\\CardAddedToHand', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Deck\\CardAddedToStable', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.addCardToOpponent({'player': response.currentPlayer, 'card': response.card})

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Deck\\DeckViewed', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Deck\\DeckShuffled', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Deck\\DiscardPileShuffledBackIntoEmptyDeck', (response) => {
                this.clearDiscardPile();

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        registerEventsFromTable() {
            this.$pusher.get().bind('App\\Events\\Game\\Table\\CardDiscarded', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.removeCardFromTable({'index': response.index});
                this.addCardToDiscardPile({'card': response.card});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\Table\\CardWentBackToHand', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.removeCardFromTable({'index': response.index});

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        registerEventsFromDiscardPile() {
            this.$pusher.get().bind('App\\Events\\Game\\DiscardPile\\CardAddedToHand', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.removeCardFromDiscardPile({'index': response.index})

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\DiscardPile\\CardAddedToStable', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.removeCardFromDiscardPile({'index': response.index})
                this.addCardToOpponent({'player': response.currentPlayer, 'card': response.card})

                messageStore.addItem({'message': response.message, 'color': response.color});
            });

            this.$pusher.get().bind('App\\Events\\Game\\DiscardPile\\DiscardPileShuffledBackIntoDeck', (response) => {
                if (response.currentPlayer.uid === this.getUser().uid) return;

                this.clearDiscardPile();

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        registerEndTurnEvent() {
            this.$pusher.get().bind('App\\Events\\Game\\TurnEnded', (response) => {
                this.discardPile = this.discardPile.concat(response.discardPile);
                this.table = [];
                this.currentPlayer = response.newPlayer.uid;
                this.myTurn = this.currentPlayer === this.getUser().uid;

                messageStore.addItem({'message': response.message, 'color': response.color});
            });
        },

        countDownForEndingTurn() {
            if (this.endTurnCounter > 0) {
                this.endTurnCounter--;
            }
        },

        addCardToHand(data) {
            this.hand.push(data.card);
        },
        removeCardFromHand(data) {
            this.hand.splice(data.index, 1);
        },
        addCardToDiscardPile(data) {
            this.discardPile.push(data.card);
        },
        removeCardFromDiscardPile(data) {
            this.discardPile.splice(data.index, 1);
        },
        clearDiscardPile() {
            this.discardPile = [];
        },
        addCardToTable(data) {
            this.table.push(data.card);
        },
        removeCardFromTable(data) {
            this.table.splice(data.index, 1);
        },
        addCardToNursery(data) {
            this.nursery.push(data.card);
        },
        removeCardFromNursery(data) {
            this.nursery.splice(data.index, 1);
        },
        addCardToStable(data) {
            this.stable.cards.push(data.card);
        },
        removeCardFromStable(data) {
            this.stable.cards.splice(data.index, 1);
        },
        addCardToOpponent(data) {
            try {
                const opponentIndex = this.opponents.findIndex((opponent) => opponent.player.uid === data.player.uid);
                this.opponents[opponentIndex].cards.push(data.card);
            } catch (error) {
                messageStore.error(error);
            }
        },
        removeCardFromOpponent(data) {
            try {
                const opponentIndex = this.opponents.findIndex((opponent) => opponent.player.uid === data.player.uid);
                this.opponents[opponentIndex].cards.splice(data.index, 1);
            } catch (error) {
                messageStore.error(error);
            }
        },

        showOpponentModal(event) {
            this.modals.opponents[event.player.uid] = {
                index: event.index,
                open: true,
            };
        },
        showOpponentHand(event) {
            this.modals.opponentHand = {
                player: event.player,
                open: true,
            };
        },
        showNurseryModal(event) {
            this.modals.nursery.index = event.index;
            this.modals.nursery.open = true;
        },
        showDeckModal() {
            this.modals.deck.open = true;
        },
        showTableModal(event) {
            this.modals.table.index = event.index;
            this.modals.table.open = true;
        },
        showDiscardPileModal(event) {
            this.modals.discardPile.index = event.index;
            this.modals.discardPile.open = true;
        },
        showStableModal(event) {
            this.modals.stable.index = event.index;
            this.modals.stable.open = true;
        },
        showHandModal(event) {
            this.modals.hand.index = event.index;
            this.modals.hand.open = true;
        },
        endTurn(event) {
            try {
                this.currentPlayer = event.player.uid;
                this.myTurn = this.currentPlayer === this.getUser().uid;
                this.table = [];
                event.discardPile.forEach((card) => this.discardPile.push(card));
            } catch (error) {
                messageStore.error(error);
            }
        },
    },
}
</script>