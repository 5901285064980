<template>
    <div class="hand" @dragover.prevent="" @drop.prevent="drop($event)">
        <card-view v-for="(card, index) in cards"
                   :key="card.uid + '_' + index"
                   :image="card.image"
                   :uid="card.uid"
                   :name="card.name"
                   :text="card.text"
                   :disabled="disabled"
                   offset-y="40"
                   @dragstart="dragStart($event, card, index)"
                   @dragend="dragEnd()"
                   @click="$emit('showModal', {'index': index});"
        ></card-view>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { messageStore } from "@/services/messageStore";
import CardView from "@/components/CardView";
import gameApi from "@/services/gameApi";

export default {
    name: 'PlayerHand',
    components: { CardView },
    props: {
        cards: Array,
    },
    data() {
        return {
            disabled: false,
        };
    },
    methods: {
        ...mapGetters(["getUser"]),
        dragStart(event, card, index) {
            this.disabled = true;
            event.dataTransfer.setData('card', JSON.stringify(card));
            event.dataTransfer.setData('source', 'hand');
            event.dataTransfer.setData('index', index);
            event.dataTransfer.setData('player', JSON.stringify(this.getUser()));
            event.currentTarget.width = '6rem';
        },
        dragEnd() {
            this.disabled = false;
        },
        drop(event) {
            const source = event.dataTransfer.getData('source');
            const index = event.dataTransfer.getData('index');
            const card = JSON.parse(event.dataTransfer.getData('card'));

            // This player always needs to be able to bring a card back from their stable to their hand.
            // During this player's turn, this could be because of Upgrade or Downgrade cards or effects of Unicorn cards.
            // When it's not this player's turn, this could also be requested by the active player.
            if (source === 'player') {
                this.addToHand(card, index);

                return;
            }

            if (source === 'table') {
                this.moveFromTableToHand(card, index);

                return;
            }

            messageStore.notAllowed();
        },
        async addToHand(card, index) {
            this.$emit('removeCardFromStable', {'index': index});
            this.$emit('addCardToHand', {'card': card});

            try {
                await gameApi.moveFromStableToHand(card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async moveFromTableToHand(card, index) {
            this.$emit('removeCardFromTable', {'index': index});
            this.$emit('addCardToHand', {'card': card});

            try {
                await gameApi.moveFromTableToHand(card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
    },
}
</script>
