<template>
  <div class="home">

  </div>
</template>

<script>

export default {
  name: 'HomeIndex',
  components: {}
}
</script>
