import axios from 'axios';

export default {
    getUrl(url) {
        url = url.startsWith('/') ? url : '/' + url;

        return process.env.VUE_APP_API_BASE_URL + '/game/' + window.localStorage.getItem('unstabled.game') + url;
    },

    drawCard() {
        return axios.get(this.getUrl(`/draw-card`));
    },
    endTurn() {
        return axios.post(this.getUrl(`/end-turn`), []);
    },

    /**
     * All possible actions you can perform with the deck
     */
    viewDeck() {
        return axios.get(this.getUrl(`/deck`));
    },
    shuffleDeck() {
        return axios.post(this.getUrl(`/deck/shuffle`), []);
    },
    moveFromDeckToHand(card, index) {
        return axios.post(this.getUrl(`/deck/card/${card.uid}/add-to-hand`), { index: index });
    },
    moveFromDeckToStable(card, index) {
        return axios.post(this.getUrl(`/deck/card/${card.uid}/add-to-stable`), { index: index });
    },

    /**
     * All possible moves coming from your hand.
     */
    fetchHand() {
        return axios.get(this.getUrl(`/hand`), []);
    },
    playInstantCard(card, index) {
        return axios.post(this.getUrl(`/hand/card/${card.uid}/instant`), { index: index });
    },
    playMagicCard(card, index) {
        return axios.post(this.getUrl(`/hand/card/${card.uid}/magic`), { index: index });
    },
    moveFromHandToDiscardPile(card, index) {
        return axios.post(this.getUrl(`/hand/card/${card.uid}/discard`), { index: index });
    },
    moveFromHandToStable(card, index) {
        return axios.post(this.getUrl(`/hand/card/${card.uid}/add-to-stable`), { index: index });
    },
    moveFromHandToOpponent(player, card, index) {
        return axios.post(this.getUrl(`/hand/card/${card.uid}/player/${player.uid}/add-to-stable`), { index: index });
    },

    /**
     * All possible moves coming from your own stable.
     */
    moveFromStableToDiscardPile(card, index) {
        return axios.post(this.getUrl(`/stable/card/${card.uid}/sacrifice`), { index: index });
    },
    moveFromStableToHand(card, index) {
        return axios.post(this.getUrl(`/stable/card/${card.uid}/back-to-hand`), { index: index });
    },
    moveFromStableToNursery(card, index) {
        return axios.post(this.getUrl(`/stable/card/${card.uid}/back-to-nursery`), { index: index });
    },
    moveFromStableToOpponent(player, card, index) {
        return axios.post(this.getUrl(`/stable/card/${card.uid}/player/${player.uid}/move`), { index: index });
    },

    /**
     * All possible moves you can perform in your opponents stable.
     */
    fetchOpponentHand(opponent) {
        return axios.get(this.getUrl(`/opponent/${opponent.uid}/hand`));
    },
    moveFromOpponentHandToHand(opponent, card, index) {
        return axios.post(this.getUrl(`/opponent/${opponent.uid}/hand/card/${card.uid}/add-to-hand`), { index: index });
    },
    moveRandomCardFromOpponentHandToHand(opponent) {
        return axios.post(this.getUrl(`/opponent/${opponent.uid}/hand/add-random-card-to-hand`), []);
    },
    switchHandsWithOpponent(opponent) {
        return axios.post(this.getUrl(`/opponent/${opponent.uid}/hand/switch`), []);
    },
    moveFromOpponentToDiscardPile(opponent, card, index) {
        return axios.post(this.getUrl(`/opponent/${opponent.uid}/stable/card/${card.uid}/destroy`), { index: index });
    },
    moveFromOpponentToStable(opponent, card, index) {
        return axios.post(this.getUrl(`/opponent/${opponent.uid}/stable/card/${card.uid}/steal`), { index: index });
    },
    moveFromOpponentToOpponent(opponent, player, card, index) {
        return axios.post(this.getUrl(`/opponent/${opponent.uid}/stable/card/${card.uid}/player/${player.uid}/move`), { index: index });
    },

    /**
     * All possible moves coming from the table.
     */
    moveFromTableToDiscardPile(card, index) {
        return axios.post(this.getUrl(`/table/card/${card.uid}/discard`), { index: index });
    },
    moveFromTableToHand(card, index) {
        return axios.post(this.getUrl(`/table/card/${card.uid}/back-to-hand`), { index: index });
    },

    /**
     * All possible moves coming from the nursery.
     */
    moveFromNurseryToStable(card, index) {
        return axios.post(this.getUrl(`/nursery/card/${card.uid}/adopt`), { index: index });
    },

    /**
     * All possible moves coming from the discard pile.
     */
    shuffleDiscardPileBackIntoDeck() {
        return axios.post(this.getUrl(`/discard-pile/shuffle-back-into-deck`), []);
    },
    moveFromDiscardPileToHand(card, index) {
        return axios.post(this.getUrl(`/discard-pile/card/${card.uid}/add-to-hand`), { index: index });
    },
    moveFromDiscardPileToStable(card, index) {
        return axios.post(this.getUrl(`/discard-pile/card/${card.uid}/add-to-stable`), { index: index });
    },
}