<template>
    <section class="vh-100 gradient-custom">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-dark text-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw-bold mb-2 text-uppercase mb-5 text-center">Reset password</h2>

                                <form @submit.prevent="submit">
                                    <div class="alert alert-danger" v-if="showError">
                                        <ul class="list-unstyled mb-0">
                                            <li v-for="(itemErrors, key) in errors" :key="key">
                                                {{ itemErrors[0] }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="email">Email address</label>
                                        <input type="email" name="email" v-model="form.email" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="password">Password</label>
                                        <input type="password" name="password" v-model="form.password" class="form-control form-control-lg">
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="password_confirmation">Password confirmation</label>
                                        <input type="password" name="password_confirmation" v-model="form.password_confirmation" class="form-control form-control-lg">
                                    </div>

                                    <div class="text-center">
                                        <button class="btn btn-outline-light btn-lg px-5" type="submit">Change password</button>
                                    </div>
                                </form>
                            </div>

                            <div class="text-center">
                                <p class="mb-0">Wait, are you saying you changed your mind? <a @click="logIn()" class="text-white-50 fw-bold">Log in!</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "AuthResetPassword",
    components: {},
    data() {
        return {
            form: {
                email: null,
                password: null,
                password_confirmation: null,
            },
            showError: false,
            errors: []
        };
    },
    methods: {
        async submit() {
            axios.post('reset-password/' + this.$route.params.token, this.form)
                .then(() => {
                    this.$router.push("/login");
                })
                .catch(error => {
                    if (error.status === 422) {
                        this.errors = error.data.errors;
                    } else {
                        this.errors = ['Whoops, something went wrong...']
                    }

                    this.showError = true;
                });
        },
        async logIn() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>

</style>