
require('bootstrap')

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios';

import gamePusher from "@/services/gamePusher";
import gameApi from "@/services/gameApi";

axios.defaults.withCredentials = true;
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;

axios.interceptors.response.use(undefined, function (error) {
    if (error) {
        if (error.response.status === 401) {
            store.dispatch('LogOut')
            return router.push('/login')
        }

        if (error.response.status === 403) {
            return router.push('/game')
        }

        return Promise.reject(error.response);
    }
});

const app = createApp(App);

app.use(store);
app.use(router);
app.mount('#app');

app.config.globalProperties.$pusher = gamePusher;
app.config.globalProperties.$api = gameApi;
