<template>
    <section class="vh-100 gradient-game">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw-bold mb-2 text-uppercase text-center">Join game</h2>
                                <p class="text-black-50 mb-5 text-center">Remember, unicorns are your friends now.</p>

                                <form @submit.prevent="submit">
                                    <div class="alert alert-danger" v-if="showError">
                                        <ul class="list-unstyled mb-0">
                                            <li v-for="(itemErrors, key) in errors" :key="key">
                                                {{ itemErrors[0] }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="uid">Invite code</label>
                                        <input type="text" name="uid" v-model="form.uid" class="form-control form-control-lg" />
                                    </div>

                                    <div class="text-center">
                                        <button class="btn btn-outline-dark btn-lg px-5" type="submit">Join</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from "axios";

export default {
    name: 'GameJoin',
    components: {},
    data() {
        return {
            form: {
                uid: null,
            },
        }
    },
    mounted: function () {
        axios.get('game/current').then((response) => {
            if (response.data.uid) {
                this.$router.push(`/game/${response.data.uid}/waiting-room`);
            }
        });

        const invite = this.$route.query.invite || null;

        if (invite) {
            this.form.uid = invite;
            this.submit();
        }
    },
    methods: {
        async submit() {
            try {
                const response = await axios.post(`game/${this.form.uid}/join`);
                this.$router.push(`/game/${response.data.uid}/waiting-room`);
            } catch (error) {
                this.errors = error.status === 422 ? error.data.errors : [['Whoops, something went wrong...']]
                this.showError = true;
            }
        },
    }
}
</script>
