<template>
    <div ref="toast" :class="'toast align-items-center ' + (textWhite ? 'text-white' : '') + ' bg-' + color +' border-0'" role="alert" aria-live="assertive" aria-atomic="true">
        <div class="d-flex">
            <div class="toast-body">
                {{ message }}
            </div>
            <button type="button" class="btn-close btn-close-white me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
    </div>
</template>

<script>

import { Toast } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
    name: "ToastMessage",
    props: {
        message: String,
        color: {
            type: String,
            default: 'primary',
        },
        textWhite: {
            type: Boolean,
            default: true,
        }
    },
    mounted: function () {
        const toast = new Toast(this.$refs.toast);
        toast.show();
    }
}
</script>

<style scoped>

</style>