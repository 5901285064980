import axios from 'axios';

const state = {
    user: null,
};

const getters = {
    isAuthenticated: state => !!state.user,
    getUser: state => state.user,
};

const actions = {
    async Register({dispatch}, form) {
        await axios.post('register', form)
        await dispatch('LogIn', {'email': form.email, 'password': form.password})
    },
    async LogIn({commit}, User) {
        const player = await axios.post('login', User)
        await commit('setUser', player.data)
    },
    async LogOut({commit}){
        let user = null
        commit('logOut', user)
    }
};

const mutations = {
    setUser(state, player){
        state.user = player
    },
    logOut(state){
        state.user = null
    },
};

export default {
    state,
    getters,
    actions,
    mutations
};