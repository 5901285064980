<template>
    <section class="vh-100 gradient-game">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw-bold mb-2 text-uppercase text-center">New game?</h2>
                                <p class="text-black-50 mb-5 text-center">Choose your deck and start a game!</p>

                                <form @submit.prevent="submit">
                                    <div class="alert alert-danger" v-if="showError">
                                        <ul class="list-unstyled mb-0">
                                            <li v-for="(itemErrors, key) in errors" :key="key">
                                                {{ itemErrors[0] }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="form-outline mb-4">
                                        <label class="form-label" for="deck">Base deck</label>
                                        <select v-model="form.deck" name="deck" class="form-control form-control-lg text-black">
                                            <option v-for="baseDeck in baseDecks" :key="baseDeck.uid" :value="baseDeck.uid">{{ baseDeck.name }}</option>
                                        </select>
                                    </div>

                                    <div class="form-outline mb-4" v-if="expansions.length">
                                        <label class="form-label">Expansions</label>
                                        <div v-for="expansion in expansions" :key="expansion.uid">
                                            <input type="checkbox" :id="expansion.uid" :value="expansion.uid" v-model="form.expansions">
                                            <label :for="expansion.uid">{{ expansion.name }}</label>
                                        </div>
                                    </div>

                                    <div class="text-center">
                                        <button class="btn btn-outline-dark btn-lg px-5" type="submit">Start</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

import axios from "axios";

export default {
    name: 'GameCreate',
    components: {},
    data() {
        return {
            baseDecks: null,
            expansions: [],
            form: {
                deck: null,
                expansions: [],
            },
            showError: false,
        }
    },
    mounted: function () {
        axios.get('game/current').then((response) => {
            if (response.data.uid) {
                this.$router.push(`/game/${response.data.uid}`);
            }
        });

        this.fetchBaseDecks();
        this.fetchExpansions();
    },
    methods: {
        fetchBaseDecks() {
            axios.get('decks/base').then((response) => {
                this.baseDecks = response.data;
                this.form.deck = response.data[0].uid || null;
            });
        },
        fetchExpansions() {
            axios.get('decks/expansions').then((response) => {
                this.expansions = response.data;
            });
        },
        async submit() {
            try {
                const response = await axios.post('game', this.form);
                this.$router.push(`/game/${response.data.uid}/waiting-room`);
            } catch (error) {
                this.errors = error.status === 422 ? error.data.errors : [['Whoops, something went wrong...']]
                this.showError = true
            }
        },
    }
}
</script>
