<template>
    <section class="vh-100 gradient-custom">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-dark text-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw-bold mb-2 text-uppercase text-center">Forgot password</h2>
                                <p class="text-white-50 mb-5 text-center">An email containing a reset link won't magically appear in your inbox. Fill your email address in underneath!</p>

                                <form @submit.prevent="submit">
                                    <div class="alert alert-danger" v-if="showError">
                                        <ul class="list-unstyled mb-0">
                                            <li v-for="(itemErrors, key) in errors" :key="key">
                                                {{ itemErrors[0] }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="alert alert-success" v-if="showSuccess">
                                        An e-mail has been sent to reset your password.
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="email">Email address</label>
                                        <input type="email" name="email" v-model="form.email" class="form-control form-control-lg">
                                    </div>

                                    <div class="text-center">
                                        <button class="btn btn-outline-light btn-lg px-5" type="submit">Request reset link</button>
                                    </div>
                                </form>
                            </div>

                            <div class="text-center">
                                <p class="mb-0">Did silly you remember your password again? <a @click="logIn()" class="text-white-50 fw-bold">Log in!</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import axios from "axios";

export default {
    name: "AuthLogin",
    components: {},
    data() {
        return {
            form: {
                email: "",
            },
            showSuccess: false,
            showError: false,
            errors: []
        };
    },
    methods: {
        async submit() {
            const User = new FormData();
            User.append("email", this.form.email);

            axios.post('forgot-password', User)
                .then(() => {
                    this.showError = false
                    this.showSuccess = true
                })
                .catch(error => {
                    if (error.status === 422) {
                        this.errors = error.data.errors;
                    } else {
                        this.errors = ['Whoops, something went wrong...']
                    }

                    this.showError = true;
                    this.showSuccess = false;
                });
        },
        async logIn() {
            this.$router.push("/login");
        },
    },
};
</script>

<style scoped>

</style>