<template>
    <div :class="'modal ' + (modalOpen ? 'open' : null)" id="discardPileModal">
        <card-list-modal :cards="cards"
                         :index="index"
                         :modal-open="modalOpen"
                         :clickable="myTurn"
                         source="discardPileModal"
                         @open-context-menu="showMenu"
                         @close-modal="$emit('closeModal')"
        ></card-list-modal>

        <div id="contextMenuDiscardPileModal" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="moveFromDeckToHand"><i class="fas fa-hand" aria-hidden="true"></i> Bring to hand</a></li>
                <li v-if="selectedCard.type !== 'instant' && selectedCard.type !== 'magic'"><a @click="moveFromDeckToStable"><i class="fas fa-horse" aria-hidden="true"></i> Add to stable</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import CardListModal from "@/components/CardListModal.vue";
import gameApi from "@/services/gameApi";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'DiscardPileModal',
    components: { CardListModal },
    props: {
        cards: Array,
        index: Number,
        modalOpen: Boolean,
        myTurn: Boolean,
    },
    data() {
        return {
            selectedCardIndex: null,
            selectedCard: {
                type: null,
            },
        }
    },
    methods: {
        showMenu(event) {
            if (this.myTurn === false) { return false; }
            this.selectedCardIndex = event.index;
            this.selectedCard = this.cards[this.selectedCardIndex];

            const menu = document.getElementById("contextMenuDiscardPileModal")
            menu.style.left = (event.event.clientX) + "px";
            menu.style.top = (event.event.clientY) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuDiscardPileModal")
            menu.style.display = 'none';
        },
        async moveFromDeckToHand() {
            this.hideMenu();
            this.$emit('closeModal');
            this.$emit('removeCardFromDiscardPile', {'index': this.selectedCardIndex});
            this.$emit('addCardToHand', {'card': this.selectedCard});

            try {
                await gameApi.moveFromDiscardPileToHand(this.selectedCard, this.selectedCardIndex)
            } catch (error) {
                messageStore.error(error);
            }
        },
        async moveFromDeckToStable() {
            this.hideMenu();
            this.$emit('closeModal');
            this.$emit('removeCardFromDiscardPile', {'index': this.selectedCardIndex});
            this.$emit('addCardToStable', {'card': this.selectedCard});

            try {
                await gameApi.moveFromDiscardPileToStable(this.selectedCard, this.selectedCardIndex)
            } catch (error) {
                messageStore.error(error);
            }
        },
    }
}
</script>
