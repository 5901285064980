<template>
    <div :class="'player ' + (myTurn ? 'active' : '') + ' ' + (dragover ? 'dragover' : '')"
         @dragover.prevent="dragOver()"
         @dragleave.prevent="dragLeave()"
         @drop.prevent="drop($event)">
        <div class="information">
            <h4 class="name">Your stable</h4>
            <div class="statistics">
                <span class="unicorns"><i class="fas fa-horse"></i> {{ amountOfUnicorns }}</span>
                <span class="upgrades"><i class="fas fa-angle-double-up"></i> {{ amountOfUpgrades }}</span>
                <span class="downgrades"><i class="fas fa-angle-double-down"></i> {{ amountOfDowngrades }}</span>
                <button v-if="myTurn" class="btn btn-primary end-turn" @click.prevent="endTurn()" :disabled="endTurnCounter > 0">
                    <span v-if="endTurnCounter > 0">({{endTurnCounter}} sec)</span>
                    <span v-else>End turn</span>
                </button>
            </div>
        </div>
        <div :class="'stable color-' + order">
            <card-view v-for="(card, index) in cards"
                       :key="card.uid + '_' + index"
                       :image="card.image"
                       :text="card.text"
                       :name="card.name"
                       :uid="card.uid"
                       :disabled="disabled"
                       @dragstart="dragStart($event, card, index)"
                       @dragend="dragEnd($event)"
                       @click="$emit('showModal', {'index': index});"
            ></card-view>
            <div v-for="index in (14 - cards.length)" :key="index" class="placeholder"></div>
        </div>
    </div>
</template>

<script>

import CardView from "@/components/CardView";
import {mapGetters} from "vuex";
import gameApi from "@/services/gameApi";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'PlayerStable',
    components: {CardView},
    props: {
        cards: Array,
        cardsInHand: Number,
        order: Number,
        myTurn: Boolean,
        endTurnCounter: Number,
    },
    data() {
        return {
            disabled: false,
            dragover: false,
        };
    },
    computed: {
        amountOfUnicorns() {
            return this.cards.reduce((count, card) => count + (['baby_unicorn', 'basic_unicorn', 'magical_unicorn'].includes(card.type)), 0);
        },
        amountOfUpgrades() {
            return this.cards.reduce((count, card) => count + (card.type === 'upgrade'), 0);
        },
        amountOfDowngrades() {
            return this.cards.reduce((count, card) => count + (card.type === 'downgrade'), 0);
        },
    },
    methods: {
        ...mapGetters(["getUser"]),
        dragStart(event, card, index) {
            this.disabled = true;
            event.dataTransfer.setData('card', JSON.stringify(card));
            event.dataTransfer.setData('source', 'player');
            event.dataTransfer.setData('index', index);
            event.dataTransfer.setData('player', JSON.stringify(this.getUser()));
        },
        dragOver() {
            this.dragover = true;
        },
        dragLeave() {
            this.dragover = false;
        },
        dragEnd() {
            this.disabled = false;
        },
        drop(event) {
            this.dragover = false;

            const source = event.dataTransfer.getData('source');
            const index = event.dataTransfer.getData('index');
            const card = JSON.parse(event.dataTransfer.getData('card'));
            const player = JSON.parse(event.dataTransfer.getData('player'));

            // Instant or Magic cards can never be added to your stable.
            if (card.type === 'instant' || card.type === 'magic') {
                messageStore.notAllowed();
                return;
            }

            // Only during this player's turn are they allowed to bring cards from their hand to their stable.
            if (source === 'hand') {
                this.myTurn === true ?
                    this.moveFromHandToStable(card, index) :
                    messageStore.notAllowed();
            }

            // During this player's turn, cards could be moved from an opponent's stable to this player's stable.
            // This could be because of Upgrade, Downgrade or Magic cards or effects of Unicorn cards.
            if (source === 'opponent') {
                this.myTurn === true ?
                    this.moveFromOpponentToStable(card, player, index) :
                    messageStore.notAllowed();
            }

            // It is not possible to move a card from the table to your stable.
            if (source === 'table') {
                messageStore.notAllowed();
            }
        },
        async moveFromHandToStable(card, index) {
            this.$emit('removeCardFromHand', {'index': index});
            this.$emit('addCardToStable', {'card': card});

            try {
                await gameApi.moveFromHandToStable(card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async moveFromOpponentToStable(card, player, index) {
            this.$emit('removeCardFromOpponent', {'index': index, 'player': player});
            this.$emit('addCardToStable', {'card': card});

            try {
                await gameApi.moveFromOpponentToStable(player, card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async endTurn() {
            if (this.cardsInHand > 7) {
                messageStore.addItem({
                    'color': 'danger',
                    'message': 'You have more than 7 cards in your hand!',
                });

                return;
            }

            try {
                await gameApi.endTurn();
            } catch (error) {
                messageStore.error(error);
            }
        }
    },
}
</script>

<style>

</style>