<template>
    <div :class="'nursery ' + (dragover ? 'dragover' : '')"
         @dragover.prevent="dragOver()"
         @dragleave.prevent="dragLeave()"
         @drop.prevent="drop($event)">
        
        <h4>Nursery</h4>
        
        <div class="last-card" id="lastCard">
            <card-view v-if="lastCard"
                       :image="lastCard.image"
                       :text="lastCard.text"
                       :name="lastCard.name"
                       :uid="lastCard.uid"
                       ondragstart="return false;"
                       @contextmenu.prevent="showMenu($event)"
            ></card-view>
            <div v-else class="placeholder"></div>
        </div>

        <div v-if="myTurn" id="contextMenuNursery" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a href="#" @click="$emit('showModal', {'cards': cards, 'index': cards.length - 1});"><i class="fa-solid fa-baby-carriage"></i> Adopt a baby</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import { messageStore } from "@/services/messageStore";
import CardView from "@/components/CardView";
import gameApi from "@/services/gameApi";

export default {
    name: 'NurseryPile',
    components: {CardView},
    props: {
        cards: Array,
        myTurn: Boolean,
    },
    data() {
        return {
            dragover: false,
        };
    },
    computed: {
        lastCard() {
            return this.cards.length > 0 ? this.cards[this.cards.length - 1] : null;
        },
    },
    methods: {
        dragOver() {
            this.dragover = true;
        },
        dragLeave() {
            this.dragover = false;
        },
        drop(event) {
            this.dragover = false;

            const source = event.dataTransfer.getData('source');
            const index = event.dataTransfer.getData('index');
            const card = JSON.parse(event.dataTransfer.getData('card'));
            const player = JSON.parse(event.dataTransfer.getData('player'));

            if (source !== 'player') {
                messageStore.notAllowed();

                return;
            }
            
            if (card.type !== 'baby_unicorn') {
                messageStore.notAllowed();

                return;
            }

            this.returnCardToNursery(player, card, index);
        },
        showMenu(event) {
            if (this.myTurn === false) return;
            
            const menu = document.getElementById("contextMenuNursery")
            const element = document.getElementById('middle').getBoundingClientRect();
            menu.style.left = (event.clientX - element.left) + "px";
            menu.style.top = (event.clientY - element.top) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuNursery")
            menu.style.display = 'none';
        },
        async returnCardToNursery(player, card, index) {
            this.$emit('removeCardFromStable', {'index': index});
            this.$emit('addCardToNursery', {'card': card});

            try {
                await gameApi.moveFromStableToNursery(card, index)
            } catch (error) {
                messageStore.error(error);
            }
        },
    },
}
</script>
