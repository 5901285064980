<template>
    <div ref="card"
         class="card"
         data-bs-toggle="tooltip"
         data-bs-placement="top"
         data-bs-html="true"
         :data-bs-offset="offsetX + ',' + offsetY"
         :title="'<h4>' + name + '</h4><p>' + text + '</p>'">
        <img :src="image" :alt="name" />
    </div>
</template>

<script>

import { Tooltip } from 'bootstrap/dist/js/bootstrap.esm.min.js'

export default {
    name: 'CardView',
    props: {
        uid: String,
        name: String,
        text: String,
        image: String,
        disabled: Boolean,
        offsetX: {
            type: String,
            default: '0',
        },
        offsetY: {
            type: String,
            default: '0',
        },
    },
    data() {
        return {
            tooltip: null,
        };
    },
    watch: {
        disabled: function (disabledValue) {
            if (disabledValue === true) {
                this.hideTooltip();
            }
        }
    },
    mounted: function () {
        this.tooltip = new Tooltip(this.$refs.card);
    },
    methods: {
        hideTooltip() {
            if (this.tooltip !== null) {
                this.tooltip.hide();
            }
        },
    }
}
</script>