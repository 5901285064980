import Pusher from 'pusher-js';

export default {
    pusher: new Pusher(process.env.VUE_APP_PUSHER_KEY, {
        cluster: process.env.VUE_APP_PUSHER_CLUSTER,
    }),

    subscribe(game) {
        Pusher.logToConsole = true;
        this.pusher.subscribe('unstabled.' + game);

        return this;
    },

    get() {
        return this.pusher;
    }
}
