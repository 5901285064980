<template>
    <div :class="'modal ' + (modalOpenAfterFetch ? 'open' : '')" id="opponentHandModal">
        <card-list-modal :cards="hand.data"
                         :index="handIndex"
                         :modal-open="modalOpenAfterFetch"
                         :clickable="myTurn"
                         source="opponentHandModal"
                         @open-context-menu="showMenu"
                         @close-modal="closeModal"
        ></card-list-modal>

        <div id="contextMenuOpponentHandModal" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="moveFromOpponentHandToHand"><i class="fas fa-hand" aria-hidden="true"></i> Bring to hand</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import CardListModal from "@/components/CardListModal.vue";
import gameApi from "@/services/gameApi";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'OpponentHandModal',
    components: { CardListModal },
    props: {
        cards: Array,
        index: Number,
        player: Object,
        modalOpen: Boolean,
        myTurn: Boolean,
    },
    data() {
        return {
            hand: {data: this.cards || []},
            handIndex: this.index || 0,
            selectedCardIndex: 0,
            selectedCard: {
                type: null,
            },
            modalOpenAfterFetch: false,
        }
    },
    watch: {
        modalOpen: function (modalOpenValue) {
            if (modalOpenValue === true && this.player !== null) {
                this.fetchOpponentHand();
            }
        }
    },
    methods: {
        async fetchOpponentHand() {
            try {
                this.hand = await gameApi.fetchOpponentHand(this.player);
            } catch (error) {
                messageStore.error(error);
            }

            this.handIndex = 0;
            this.modalOpenAfterFetch = true;
        },
        closeModal() {
            this.hand = [];
            this.handIndex = 0;
            this.modalOpenAfterFetch = false;
            this.$emit('closeModal')
        },
        showMenu(event) {
            if (this.myTurn === false) { return false; }
            this.selectedCardIndex = event.index;
            this.selectedCard = this.hand.data[this.selectedCardIndex];

            const menu = document.getElementById("contextMenuOpponentHandModal")
            menu.style.left = (event.event.clientX) + "px";
            menu.style.top = (event.event.clientY) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuOpponentHandModal")
            menu.style.display = 'none';
        },
        async moveFromOpponentHandToHand() {
            this.hideMenu();
            this.closeModal();
            this.$emit('addCardToHand', {'card': this.selectedCard});

            try {
                await gameApi.moveFromOpponentHandToHand(this.player, this.selectedCard, this.selectedCardIndex)
            } catch (error) {
                messageStore.error(error);
            }
        },
    }
}
</script>
