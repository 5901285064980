<template>
    <div :class="'modal ' + (modalOpen ? 'open' : '')" :id="'opponentStableModal' + player.uid">
        <card-list-modal :cards="cards"
                         :index="index"
                         :modal-open="modalOpen"
                         :clickable="myTurn"
                         :source="'opponentStableModal' + player.uid"
                         @close-modal="$emit('closeModal')"
        ></card-list-modal>
    </div>
</template>

<script>

import CardListModal from "@/components/CardListModal.vue";

export default {
    name: 'PlayerStableModal',
    components: { CardListModal },
    props: {
        player: Object,
        cards: Array,
        index: Number,
        modalOpen: Boolean,
        myTurn: Boolean,
    },
}
</script>
