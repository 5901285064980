import { reactive } from 'vue'

export const messageStore = reactive({
    messages: [],
    flash: [],

    addMessages(messages) {
        const self = this;

        messages.forEach(function (message) {
            const item = self.formatItem(message[0]);
            self.addMessageItem(item);
        })
    },

    removeItem(index) {
        this.flash.splice(index, 1);
    },

    addFlashItem(item) {
        item.timeOut = item.timeOut ? item.timeOut : 5000;

        this.flash.push(item);
        this.autoRemoveMessageUponTimeout(item);
    },

    addMessageItem(item) {
        this.messages.unshift(item);
    },

    addItem(item) {
        item = this.formatItem(item);

        this.addFlashItem(item);
        this.addMessageItem(item);
    },

    formatItem(item) {
        item.id = Date.now();
        item.color = item.color ? item.color : 'primary';
        item.textWhite = item.color === 'danger' || item.color === 'primary';

        return item;
    },

    notAllowed() {
        this.addFlashItem({
            'message': 'You are not allowed to perform this action!',
            'color': 'danger',
        });
    },

    error(error) {
        console.log(error);

        this.addFlashItem({
            'message': 'Whoops! Something went wrong! Please reload the page.',
            'color': 'danger',
        });
    },

    removeByMessage(message) {
        this.removeItem(this.flash.findIndex(item => item.message = message));
    },

    autoRemoveMessageUponTimeout(item) {
        const self = this;
        setTimeout(function() {
            self.removeByMessage(item.message);
        }, item.timeOut);
        return item.timeOut;
    }
});