<template>
    <div :class="'modal ' + (modalOpenAfterFetch ? 'open' : '')" id="cardDeckModal">
        <card-list-modal :cards="deck.data"
                         :index="deckIndex"
                         :modal-open="modalOpenAfterFetch"
                         :clickable="myTurn"
                         source="cardDeckModal"
                         @open-context-menu="showMenu"
                         @close-modal="closeModal"
        ></card-list-modal>

        <div id="contextMenuCardDeckModal" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="moveFromDeckToHand"><i class="fas fa-hand" aria-hidden="true"></i> Bring to hand</a></li>
                <li v-if="selectedCard.type !== 'instant' && selectedCard.type !== 'magic'"><a @click="moveFromDeckToStable"><i class="fas fa-horse" aria-hidden="true"></i> Add to stable</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import CardListModal from "@/components/CardListModal.vue";
import gameApi from "@/services/gameApi";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'CardDeckModal',
    components: { CardListModal },
    props: {
        cards: Array,
        index: Number,
        modalOpen: Boolean,
        myTurn: Boolean,
    },
    data() {
        return {
            deck: {data: this.cards || []},
            deckIndex: this.index || 0,
            selectedCardIndex: 0,
            selectedCard: {
                type: null,
            },
            modalOpenAfterFetch: false,
        }
    },
    watch: {
        modalOpen: function (modalOpenValue) {
            if (modalOpenValue === true) {
                this.fetchDeck();
            }
        }
    },
    methods: {
        async fetchDeck() {
            try {
                this.deck = await gameApi.viewDeck()
            } catch (error) {
                messageStore.error(error);
            }

            this.deckIndex = this.deck.data.length > 0 ? this.deck.data.length - 1 : 0;
            this.modalOpenAfterFetch = true;
        },
        closeModal() {
            this.deck = [];
            this.deckIndex = 0;
            this.modalOpenAfterFetch = false;
            this.$emit('closeModal')
        },
        showMenu(event) {
            if (this.myTurn === false) { return false; }
            this.selectedCardIndex = event.index;
            this.selectedCard = this.deck.data[this.selectedCardIndex];

            const menu = document.getElementById("contextMenuCardDeckModal")
            menu.style.left = (event.event.clientX) + "px";
            menu.style.top = (event.event.clientY) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuCardDeckModal")
            menu.style.display = 'none';
        },
        async moveFromDeckToHand() {
            this.hideMenu();
            this.closeModal();
            this.$emit('addCardToHand', {'card': this.selectedCard});

            try {
                await gameApi.moveFromDeckToHand(this.selectedCard, this.selectedCardIndex)
            } catch (error) {
                messageStore.error(error);
            }

            await this.shuffleDeck();
        },
        async moveFromDeckToStable() {
            this.hideMenu();
            this.closeModal();
            this.$emit('addCardToStable', {'card': this.selectedCard});

            try {
                await gameApi.moveFromDeckToStable(this.selectedCard, this.selectedCardIndex)
            } catch (error) {
                messageStore.error(error);
            }

            await this.shuffleDeck();
        },
        async shuffleDeck() {
            try {
                await gameApi.shuffleDeck()
            } catch (error) {
                messageStore.error(error);
            }
        }
    }
}
</script>
