<template>
    <div class="card-list">
        <div class="cards">
            <div :class="'card ' + (clickable ? 'clickable' : null)"
                 v-for="(card, index) in cards"
                 :key="card.uid + '_' + index"
                 :data-name="card.name"
                 :data-text="card.text"
                 :style="'background-image: url(\'' + card.image + '\')'"
                 @contextmenu.prevent="$emit('openContextMenu', {'event': $event, 'card': card, 'index': index})"
            >
            </div>
        </div>

        <a id="close" class="btn btn-dark text-danger" @click="close"><i class="fas fa-times"></i></a>
        <a :id="source + 'CardsModalPrev'" class="btn btn-dark btn-cards-modal-prev"><i class="fas fa-chevron-left"></i></a>
        <a :id="source + 'CardsModalNext'" class="btn btn-dark btn-cards-modal-next"><i class="fas fa-chevron-right"></i></a>
    </div>
</template>

<script>

export default {
    name: 'CardListModal',
    props: {
        cards: Array,
        index: Number,
        modalOpen: Boolean,
        clickable: Boolean,
        source: String,
    },
    data() {
        return {
            items: [],
            itemToDisplay: 0,
            offset: 60,
            rotation: 30,
            baseZIndex: 10,
            maxZIndex: 142,
            selectedCardIndex: null,
        };
    },
    watch: {
        modalOpen: function (modalOpenValue) {
            if (modalOpenValue === true) {
                this.$nextTick(function () {
                    this.init();
                });
            }
        }
    },
    mounted() {
        this.registerEvents();
    },
    methods: {
        close() {
            this.$emit('closeModal');
            this.items = [];
            this.itemToDisplay = 0;
        },
        init() {
            const modal = document.getElementById(this.source);

            this.items = [].slice.call(modal.getElementsByClassName('card'));
            console.log(this.items.length);
            this.itemToDisplay = this.index;

            this.render();
        },
        render() {
            this.items.forEach((item, i) => {
                if (i < this.itemToDisplay) {
                    item.style.transform = "translateX(-" + (this.offset * (this.itemToDisplay - i)) + "%) rotateY(" + this.rotation +"deg) scale(0.9)";
                    item.style.zIndex = this.baseZIndex;
                }

                if (i === this.itemToDisplay) {
                    item.style.transform = "rotateY(0deg)";
                    item.style.zIndex = this.maxZIndex;
                }

                if (i > this.itemToDisplay) {
                    item.style.transform = "translateX(" + (this.offset * (i - this.itemToDisplay)) + "%) rotateY(-"+ this.rotation + "deg) scale(0.9)";
                    item.style.zIndex = this.baseZIndex + (this.items.length - i);
                }
            })
        },
        flowRight() {
            console.log('right');
            if (this.itemToDisplay) {
                this.itemToDisplay--;
                this.render();
            }
        },
        flowLeft() {
            console.log('left');
            if( this.items.length > ( this.itemToDisplay + 1)  ) {
                this.itemToDisplay++;
                this.render();
            }
        },
        registerEvents() {
            document.addEventListener('keydown', this.keyDown, false);
            document.getElementById(this.source + 'CardsModalPrev').addEventListener('click', this.flowRight);
            document.getElementById(this.source + 'CardsModalNext').addEventListener('click', this.flowLeft);
        },
        keyDown(event) {
            switch( event.keyCode ) {
                case 13: this.close(); break;
                case 27: this.close(); break;
                case 37: this.flowRight(); break;
                case 39: this.flowLeft(); break;
            }
        },
    }
}
</script>