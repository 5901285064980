<template>
    <div class="toast-container position-fixed bottom-0 left-0 p-3">
        <toast-message v-for="message in messageStore.flash" :key="message.id" :message="message.message" :color="message.color" :text-white="message.textWhite"></toast-message>
    </div>
</template>

<script>

import ToastMessage from "@/components/ToastMessage";
import { messageStore } from "@/services/messageStore";

export default {
    name: "ToastContainer",
    components: { ToastMessage },
    data() {
        return {
            messageStore
        }
    }
}
</script>