import {createRouter, createWebHistory} from 'vue-router'
import store from "../store"
import RegisterForm from "@/views/Auth/RegisterForm";
import LoginForm from "@/views/Auth/LoginForm";
import ForgotPasswordForm from "@/views/Auth/ForgotPasswordForm";
import ResetPasswordForm from "@/views/Auth/ResetPasswordForm";
import HomeIndex from "@/views/HomeIndex";
import GameCreate from "@/views/Game/GameCreate";
import GameJoin from "@/views/Game/GameJoin";
import UnstableUnicorns from "@/views/UnstableUnicorns";
import GameWaitingRoom from "@/views/Game/GameWaitingRoom";


const routes = [
    {
        path: '/',
        name: 'home',
        component: HomeIndex
    },
    {
        path: '/register',
        name: "Unstabled.io - Register",
        component: RegisterForm,
        meta: { guest: true },
    },
    {
        path: '/login',
        name: "Unstabled.io - Login",
        component: LoginForm,
        meta: { guest: true },
    },
    {
        path: '/forgot-password',
        name: "Unstabled.io - Forgot password",
        component: ForgotPasswordForm,
        meta: { guest: true },
    },
    {
        path: '/reset-password/:token',
        name: "Unstabled.io - Reset password",
        component: ResetPasswordForm,
        meta: { guest: true },
    },
    {
        path: "/game",
        name: "Unstabled.io - Create game",
        component: GameCreate,
        meta: { requiresAuth: true },
    },
    {
        path: "/game/join",
        name: "Unstabled.io - Join game",
        component: GameJoin,
        meta: { requiresAuth: true },
    },
    {
        path: "/game/:uid/waiting-room",
        name: "Unstabled.io - Waiting room",
        component: GameWaitingRoom,
        meta: { requiresAuth: true },
    },
    {
        path: "/game/:uid",
        name: "Unstabled.io - Unstable Unicorns",
        component: UnstableUnicorns,
        meta: { requiresAuth: true },
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.getters.isAuthenticated) {
            next()
            return
        }
        next('/login')
    } else {
        next()
    }
});

router.beforeEach((to, from, next) => {
    if (to.matched.some((record) => record.meta.guest)) {
        if (store.getters.isAuthenticated) {
            next("/game");
            return;
        }
        next();
    } else {
        next();
    }
});

export default router
