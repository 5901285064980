<template>
    <section class="vh-100 gradient-custom">
        <div class="container py-5 h-100">
            <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                    <div class="card bg-dark text-white" style="border-radius: 1rem;">
                        <div class="card-body p-5">
                            <div class="mb-md-5 mt-md-4 pb-5">
                                <h2 class="fw-bold mb-2 text-uppercase text-center">Login</h2>
                                <p class="text-white-50 mb-5 text-center">Please enter your login and password!</p>

                                <form @submit.prevent="submit">
                                    <div class="alert alert-danger" v-if="showError">
                                        <ul class="list-unstyled mb-0">
                                            <li v-for="(itemErrors, key) in errors" :key="key">
                                                {{ itemErrors[0] }}
                                            </li>
                                        </ul>
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="email">Email address</label>
                                        <input type="text" name="email" v-model="form.email" class="form-control form-control-lg" />
                                    </div>

                                    <div class="form-outline form-white mb-4">
                                        <label class="form-label" for="password">Password</label>
                                        <input type="password" name="password" v-model="form.password" class="form-control form-control-lg" />
                                    </div>

                                    <p class="small mb-5 pb-lg-2 text-center"><a class="text-white-50" href="#" @click="forgotPassword()">Forgot password?</a></p>

                                    <div class="text-center">
                                        <button class="btn btn-outline-light btn-lg px-5" type="submit">Login</button>
                                    </div>
                                </form>
                            </div>

                            <div class="text-center">
                                <p class="mb-0">Don't have an account? <a @click="register()" class="text-white-50 fw-bold">Sign Up</a></p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
import { mapActions } from "vuex";

export default {
    name: "AuthLogin",
    components: {},
    data() {
        return {
            form: {
                email: "",
                password: "",
            },
            showError: false,
            errors: []
        };
    },
    methods: {
        ...mapActions(["LogIn"]),
        async submit() {
            const User = new FormData();
            User.append("email", this.form.email);
            User.append("password", this.form.password);

            try {
                await this.LogIn(User);

                this.$router.push("/game");
                this.showError = false
            } catch (error) {
                if (error.status === 422) {
                    this.errors = error.data.errors;
                } else {
                    this.errors = [['Whoops, something went wrong...']]
                }

                this.showError = true
            }
        },
        async forgotPassword() {
            this.$router.push("/forgot-password");
        },
        async register() {
            this.$router.push("/register");
        },
    },
};
</script>

<style scoped>

</style>