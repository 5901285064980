<template>
    <div>
        <div :class="'opponent opponent-' + index + ' ' + (active ? 'active' : '') + ' ' + (dragover ? 'dragover' : '')"
             :id="'opponentStable' + index"
             @dragover.prevent="dragOver()"
             @dragleave.prevent="dragLeave()"
             @drop.prevent="drop($event)"
             @contextmenu.prevent="showMenu($event)">
            <div class="information">
                <h4 class="name">{{ player.username }}</h4>
                <div class="statistics">
                    <span class="unicorns"><i class="fas fa-horse"></i> {{ amountOfUnicorns }}</span>
                    <span class="upgrades"><i class="fas fa-angle-double-up"></i> {{ amountOfUpgrades }}</span>
                    <span class="downgrades"><i class="fas fa-angle-double-down"></i> {{ amountOfDowngrades }}</span>
                </div>
            </div>
            <div :class="'stable color-' + order">
                <card-view v-for="(card, index) in cards"
                           :key="card.uid + '_' + index"
                           :image="card.image"
                           :text="card.text"
                           :name="card.name"
                           :uid="card.uid"
                           :disabled="disabled"
                           @dragstart="dragStart($event, card, index)"
                           @dragend="dragEnd($event)"
                           @click="$emit('showModal', {'player': player, 'index': index});"
                ></card-view>
                <div v-for="index in (14 - cards.length)" :key="index" class="placeholder"></div>
            </div>
        </div>

        <div v-if="myTurn" :id="'contextMenuOpponent' + index" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="$emit('showHand', {'player': player})"><i class="fas fa-eye" aria-hidden="true"></i> View their hand</a></li>
                <li><a @click="moveRandomCardFromOpponentHandToHand"><i class="fas fa-plus" aria-hidden="true"></i> Take random card from their hand</a></li>
                <li class="divider"></li>
                <li><a @click="switchHands"><i class="fas fa-random" aria-hidden="true"></i> Switch hands</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import { messageStore } from "@/services/messageStore";
import CardView from "@/components/CardView";
import gameApi from "@/services/gameApi";

export default {
    name: 'OpponentStable',
    components: { CardView },
    props: {
        player: Object,
        cards: Array,
        order: Number,
        index: Number,
        active: Boolean,
        myTurn: Boolean,
    },
    data() {
        return {
            disabled: false,
            dragover: false,
        };
    },
    computed: {
        amountOfUnicorns() {
            return this.cards.reduce((count, card) => count + (['baby_unicorn', 'basic_unicorn', 'magical_unicorn'].includes(card.type)), 0);
        },
        amountOfUpgrades() {
            return this.cards.reduce((count, card) => count + (card.type === 'upgrade'), 0);
        },
        amountOfDowngrades() {
            return this.cards.reduce((count, card) => count + (card.type === 'downgrade'), 0);
        },
    },
    methods: {
        showMenu(event) {
            if (this.myTurn === false) { return false; }

            const menu = document.getElementById("contextMenuOpponent" + this.index)
            menu.style.left = (event.clientX) + "px";
            menu.style.top = (event.clientY) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuOpponent" + this.index)
            menu.style.display = 'none';
        },
        dragStart(event, card, index) {
            this.disabled = true;
            event.dataTransfer.setData('card', JSON.stringify(card));
            event.dataTransfer.setData('source', 'opponent');
            event.dataTransfer.setData('index', index);
            event.dataTransfer.setData('player', JSON.stringify(this.player));
        },
        dragOver() {
            this.dragover = true;
        },
        dragLeave() {
            this.dragover = false;
        },
        dragEnd() {
            this.disabled = false;
        },
        drop(event) {
            this.dragover = false;

            const source = event.dataTransfer.getData('source');
            const card = JSON.parse(event.dataTransfer.getData('card'));
            const player = JSON.parse(event.dataTransfer.getData('player'));
            const index = event.dataTransfer.getData('index');

            // Instant or Magic cards can never be added to your stable.
            if (card.type === 'instant' || card.type === 'magic') {
                messageStore.notAllowed();

                return;
            }

            if (source === 'opponent') {
                this.myTurn
                    ? this.moveFromOpponentToOpponent(player, card, index)
                    : messageStore.notAllowed();

                return;
            }

            if (source === 'hand') {
                this.myTurn === true ?
                    this.moveFromHandToOpponent(card, index) :
                    messageStore.notAllowed();
            }

            if (source === 'player') {
                this.myTurn === true || this.active === true ?
                    this.moveFromPlayerToOpponent(card, index) :
                    messageStore.notAllowed();
            }
        },
        async moveFromHandToOpponent(card, index) {
            this.$emit('removeCardFromHand', {'index': index});
            this.$emit('addCardToOpponent', {'card': card, 'player': this.player});

            try {
                await gameApi.moveFromHandToOpponent(this.player, card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async moveFromPlayerToOpponent(card, index) {
            this.$emit('removeCardFromStable', {'index': index});
            this.$emit('addCardToOpponent', {'card': card, 'player': this.player});

            try {
                await gameApi.moveFromStableToOpponent(this.player, card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async moveFromOpponentToOpponent(opponent, card, index) {
            this.$emit('removeCardFromOpponent', {'index': index, 'player': opponent});
            this.$emit('addCardToOpponent', {'card': card, 'player': this.player});

            try {
                await gameApi.moveFromOpponentToOpponent(opponent, this.player, card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async moveRandomCardFromOpponentHandToHand() {
            this.hideMenu();

            try {
                await gameApi.moveRandomCardFromOpponentHandToHand(this.player);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async switchHands() {
            this.hideMenu();

            try {
                await gameApi.switchHandsWithOpponent(this.player);
            } catch (error) {
                messageStore.error(error);
            }
        },
    },
}
</script>