<template>
    <div :class="'discard-pile ' + (dragover ? 'dragover' : '')"
         @dragover.prevent="dragOver()"
         @dragleave.prevent="dragLeave()"
         @drop.prevent="drop($event)">
        
        <h4>Discard pile</h4>
        
        <div class="last-card" id="lastCard">
            <card-view v-if="lastCard"
                       :image="lastCard.image"
                       :text="lastCard.text"
                       :name="lastCard.name"
                       :uid="lastCard.uid"
                       ondragstart="return false;"
                       @contextmenu.prevent="showMenu($event)"
            ></card-view>
            <div v-else class="placeholder"></div>
        </div>

        <div v-if="myTurn && cards.length >= 1" id="contextMenuDiscardPile" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="shuffleBackIntoDeck"><i class="fas fa-random" aria-hidden="true"></i> Shuffle back into deck</a></li>
                <li><a @click="$emit('showModal', {'index': cards.length - 1});"><i class="fas fa-eye" aria-hidden="true"></i> Take a look</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import { messageStore } from "@/services/messageStore";
import CardView from "@/components/CardView";
import gameApi from "@/services/gameApi";

export default {
    name: 'DiscardPile',
    components: {CardView},
    props: {
        cards: Array,
        myTurn: Boolean,
    },
    data() {
        return {
            dragover: false,
        };
    },
    computed: {
        lastCard() {
            return this.cards.length > 0 ? this.cards[this.cards.length - 1] : null;
        },
    },
    methods: {
        dragOver() {
            this.dragover = true;
        },
        dragLeave() {
            this.dragover = false;
        },
        drop(event) {
            this.dragover = false;

            const source = event.dataTransfer.getData('source');
            const index = event.dataTransfer.getData('index');
            const card = JSON.parse(event.dataTransfer.getData('card'));
            const player = JSON.parse(event.dataTransfer.getData('player'));

            if (card.type === 'baby_unicorn') { messageStore.notAllowed(); return; }

            if (source === 'opponent') {
                this.myTurn
                    ? this.discardCardFromOpponent(player, card, index)
                    : messageStore.notAllowed();
            }

            if (source === 'hand') {
                this.discardCardFromHand(card, index);
            }

            if (source === 'player') {
                this.discardCardFromStable(card, index);
            }

            if (source === 'table') {
                this.discardCardFromTable(card, index);
            }
        },
        showMenu(event) {
            if (this.myTurn === false) { return false; }

            const menu = document.getElementById("contextMenuDiscardPile")
            const element = document.getElementById('middle').getBoundingClientRect();
            menu.style.left = (event.clientX - element.left) + "px";
            menu.style.top = (event.clientY - element.top) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuDiscardPile")
            menu.style.display = 'none';
        },
        async discardCardFromHand(card, index) {
            this.$emit('removeCardFromHand', {'index': index});
            this.$emit('addCardToDiscardPile', {'card': card});

            try {
                await gameApi.moveFromHandToDiscardPile(card, index)
            } catch (error) {
                messageStore.error(error);
            }
        },
        async discardCardFromStable(card, index) {
            this.$emit('removeCardFromStable', {'index': index});
            this.$emit('addCardToDiscardPile', {'card': card});

            try {
                await gameApi.moveFromStableToDiscardPile(card, index)
            } catch (error) {
                messageStore.error(error);
            }
        },
        async discardCardFromOpponent(player, card, index) {
            this.$emit('removeCardFromOpponent', {'player': player, 'index': index});
            this.$emit('addCardToDiscardPile', {'card': card});

            try {
                await gameApi.moveFromOpponentToDiscardPile(player, card, index)
            } catch (error) {
                messageStore.error(error);
            }
        },
        async discardCardFromTable(card, index) {
            this.$emit('removeCardFromTable', {'index': index});
            this.$emit('addCardToDiscardPile', {'card': card});

            try {
                await gameApi.moveFromTableToDiscardPile(card, index)
            } catch (error) {
                messageStore.error(error);
            }
        },
        async shuffleBackIntoDeck() {
            this.$emit('clearDiscardPile');

            try {
                await gameApi.shuffleDiscardPileBackIntoDeck();
            } catch (error) {
                messageStore.error(error);
            }
        },
    },
}
</script>
