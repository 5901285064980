<template>
    <div :class="'modal ' + (modalOpen ? 'open' : null)" id="nurseryPileModal">
        <card-list-modal :cards="cards"
                         :index="index"
                         :modal-open="modalOpen"
                         :clickable="myTurn"
                         source="nurseryPileModal"
                         @open-context-menu="showMenu"
                         @close-modal="$emit('closeModal')"
        ></card-list-modal>

        <div id="contextMenuNurseryModal" class="context-menu" style="display: none" @mouseleave="hideMenu()">
            <ul class="menu">
                <li><a @click="adopt"><i class="fas fa-baby-carriage" aria-hidden="true"></i> Adopt this baby!</a></li>
            </ul>
        </div>
    </div>
</template>

<script>

import CardListModal from "@/components/CardListModal.vue";
import gameApi from "@/services/gameApi";
import {messageStore} from "@/services/messageStore";

export default {
    name: 'NurseryPileModal',
    components: { CardListModal },
    props: {
        cards: Array,
        index: Number,
        modalOpen: Boolean,
        myTurn: Boolean,
    },
    data() {
        return {
            selectedCardIndex: null,
        }
    },
    methods: {
        showMenu(event) {
            if (this.myTurn === false) { return false; }
            this.selectedCardIndex = event.index;

            const menu = document.getElementById("contextMenuNurseryModal")
            menu.style.left = (event.event.clientX) + "px";
            menu.style.top = (event.event.clientY) + "px";
            menu.style.display = 'block';
        },
        hideMenu() {
            const menu = document.getElementById("contextMenuNurseryModal")
            menu.style.display = 'none';
        },
        async adopt() {
            if (this.myTurn === false) { return false; }

            const card = this.cards[this.selectedCardIndex];

            this.hideMenu();
            this.$emit('addCardToStable', {'card': card});
            this.$emit('removeCardFromNursery', {'index': this.selectedCardIndex});
            this.$emit('closeModal');

            try {
                await gameApi.moveFromNurseryToStable(card, this.selectedCardIndex);
            } catch (error) {
                messageStore.error(error);
            }
        }
    }
}
</script>
