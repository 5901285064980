<template>
    <div :class="'table-zone ' + (dragover ? 'dragover' : '')"
         @dragover.prevent="dragOver()"
         @dragleave.prevent="dragLeave()"
         @drop.prevent="drop($event)">
        <div class="drop-zone">
            <card-view v-for="(card, index) in cards"
                       :key="card.uid + '_' + index"
                       :image="card.image"
                       :text="card.text"
                       :name="card.name"
                       :uid="card.uid"
                       @click="$emit('showModal', {'cards': cards, 'index': index});"
                       @dragstart="dragStart($event, card, index)"
                       @dragend="dragEnd($event)"
            ></card-view>
        </div>
    </div>
</template>

<script>

import { mapGetters } from "vuex";
import { messageStore } from "@/services/messageStore";
import CardView from "@/components/CardView";
import gameApi from "@/services/gameApi";

export default {
    name: "TableZone",
    components: { CardView },
    props: {
        myTurn: Boolean,
        cards: Array,
    },
    data() {
        return {
            disabled: false,
            dragover: false,
        };
    },
    methods: {
        ...mapGetters(["getUser"]),
        dragStart(event, card, index) {
            this.disabled = true;
            event.dataTransfer.setData('card', JSON.stringify(card));
            event.dataTransfer.setData('source', 'table');
            event.dataTransfer.setData('index', index);
            event.dataTransfer.setData('player', JSON.stringify(this.getUser()));
        },
        dragOver() {
            this.dragover = true;
        },
        dragLeave() {
            this.dragover = false;
        },
        dragEnd() {
            this.disabled = false;
        },
        drop(event) {
            this.dragover = false;

            const source = event.dataTransfer.getData('source');
            const index = event.dataTransfer.getData('index');
            const card = JSON.parse(event.dataTransfer.getData('card'));

            if (source !== 'hand') { messageStore.notAllowed(); return; }
            if (card.type !== 'instant' && card.type !== 'magic') { messageStore.notAllowed(); return; }

            if (card.type === 'instant') {
                this.playInstantCard(card, index);
            }

            if (card.type === 'magic') {
                this.myTurn === true ?
                    this.playMagicCard(card, index) :
                    messageStore.notAllowed();
            }
        },
        async playInstantCard(card, index) {
            this.$emit('removeCardFromHand', {'index': index});
            this.$emit('addCardToTable', {'card': card});

            try {
                await gameApi.playInstantCard(card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
        async playMagicCard(card, index) {
            this.$emit('removeCardFromHand', {'index': index});
            this.$emit('addCardToTable', {'card': card});

            try {
                await gameApi.playMagicCard(card, index);
            } catch (error) {
                messageStore.error(error);
            }
        },
    },
}
</script>

<style scoped>

</style>