<template>
    <toast-container></toast-container>
    <router-view/>
</template>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;800&display=swap');

@font-face {
    font-family: 'OstrichSans';
    src: url('./assets/font/OstrichSans-Heavy.ttf.eot');
    src: url('./assets/font/OstrichSans-Heavy.ttf.woff') format('woff');
}

#app {
    font-family: 'Open Sans', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
</style>
<script>
import ToastContainer from "@/components/ToastContainer";

export default {
    components: {ToastContainer}
}
</script>